import { Box } from "@pallet-hq/hegel"
import { LoginForm } from "components/Modals/LoginModal"

const Login = () => (
    <Box height="100vh">
        <LoginForm />
    </Box>
)

export default Login
