import { useMutation, useQuery } from "@apollo/client"
import config from "config"
import { GetServerSideProps } from "next"
import Login from "page-components/login"
import { useEffect } from "react"
import {
    CHECK_AUTH_QUERY,
    CREATE_LOGIN_TOKEN_MUTATION,
    getInitialUrl,
    handlePostAuthentication,
} from "utils/auth"
import Head from "utils/Head"
import { appendQueryParams } from "utils/router"
import { CheckAuthQuery } from "__generated__/CheckAuthQuery"
import { CreateLoginTokenMutation } from "__generated__/CreateLoginTokenMutation"

export const getServerSideProps: GetServerSideProps = async context => {
    let host = context.req.headers.host || ""

    // The login page can only be visited at the CANONICAL_HOST.
    if (
        host !== config.CANONICAL_HOST &&
        context.query["noredirect"] === undefined
    ) {
        return {
            redirect: {
                destination: appendQueryParams(
                    `${config.CANONICAL_URL}/login`,
                    {
                        from: encodeURIComponent(
                            new URL(host, "http://${host}").toString()
                        ),
                    }
                ),
                permanent: true,
            },
        }
    }
    return {
        props: {},
    }
}

const LoginPage = () => {
    const checkAuthQueryResponse = useQuery<CheckAuthQuery>(CHECK_AUTH_QUERY)
    const [createLoginToken] = useMutation<CreateLoginTokenMutation>(
        CREATE_LOGIN_TOKEN_MUTATION
    )
    useEffect(() => {
        createLoginToken().then(response => {
            let xtokenx = response.data?.createLoginToken?.xtokenx
            if (xtokenx) {
                let initialUrl = getInitialUrl()
                handlePostAuthentication({
                    initialUrl: initialUrl,
                    event: "login",
                    xtokenx: xtokenx,
                })
            }
        })
    }, [createLoginToken])

    if (checkAuthQueryResponse.loading) return null
    if (checkAuthQueryResponse.data?.me) return null

    return (
        <>
            <Head description="Sign into Pallet." isVisible />
            <Login />
        </>
    )
}

export default LoginPage
